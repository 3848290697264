import { Fragment } from "react";
import { TitleHeader, CardReport } from "../../components";
import lista_usuario_icon from "../../assets/lista_usuario.png";
import money_icon from "../../assets/money.png";
import teamwork_icon from "../../assets/teamwork.png";
import location_icon from "../../assets/location.png";
import restroom_icon from "../../assets/restroom.png";
import search_icon from "../../assets/search.png";
import engineer_icon from "../../assets/engineer.png";
import camera_icon from "../../assets/camera.png";
import check_icon from "../../assets/check.png";
import observation_icon from "../../assets/observation.png";
import note_icon from "../../assets/note.png";
import { reportQuery } from "../../api/sistema";
import { reportQuery as reportIngresante } from "../../api/ingresante";
import { DATE_LIKE_NAME } from "../../utils/functions";
import { TITLE_SISTEMA_ABREV, OBJ_ROLES } from "../../utils/shared";
import { getPeriodo } from "../../utils/functions";
import { useAuth } from "../../utils/useAuth";

const idPeriodo = getPeriodo()?.idperiodo || 0;

function Reportes(props) {
  const { rol } = useAuth();

  // ------------- Descarga supervisores -------------
  const downloadExcelResumen = async () => {
    const blob = await reportQuery({
      query: "get_reporte_registrados_por_periodo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-RESUMEN-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadExcelSegundaEspecialidadDetallado = async () => {
    const blob = await reportQuery({
      query: "get_reporte_segunda_especialidad_registrados_por_periodo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${TITLE_SISTEMA_ABREV}-DETALLADO-SEG-ESP-${DATE_LIKE_NAME}.xlsx`
    );
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  // grados y títulos, error de admsión, se soluciona, error de la señora que presente documentos administrativos, consultar reniec un c4 si aparece así, un c4, sistema de reniec como lo tiene, sistema de PIDE,
  // dsfsdf, DNI a grados y títulos.

  const downloadPostulanteDetallado = async () => {
    const blob = await reportQuery({
      query: "get_reporte_postulantes_detallado_periodo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-DETALLADO-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadInscritosPorCanal = async () => {
    const blob = await reportQuery({
      query: "get_reporte_cantidad_inscritos_por_canal",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-POR-CANAL-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadInscritosPorResidencia = async () => {
    const blob = await reportQuery({
      query: "get_reporte_inscritos_por_residencia",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-POR-RESIDENCIA-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReporteMedioPago = async () => {
    const blob = await reportQuery({
      query: "get_reporte_por_medios_pago",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-MEDIO-PAGO-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReportePorSexo = async () => {
    const blob = await reportQuery({
      query: "get_reporte_de_cantidad_postulantes_por_sexo",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-REPORTE-POR-SEXO-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadIngresantes = async () => {
    const blob = await reportIngresante({
      query: "get_reporte_de_total_ingresantes"
      // variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${TITLE_SISTEMA_ABREV}-REPORTE-INGRESANTES-${DATE_LIKE_NAME}.xlsx`
    );
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadIngresantesFirmadosObs = async () => {
    const blob = await reportIngresante({
      query: "get_reporte_ingresantes_firmados_datos_faltantes"
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${TITLE_SISTEMA_ABREV}-REPORTE-INGRESANTES-FIRMADOS-OBSERVADOS-${DATE_LIKE_NAME}.xlsx`
    );
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReporteFotografiaRecortada = async () => {
    const blob = await reportQuery({
      query: "get_reporte_recorte_fotos",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-REPORTE-FOTOS-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  const downloadReporteDetalladoOK = async () => {
    const blob = await reportQuery({
      query: "get_reporte_postulantes_detallado_periodo_OK",
      variables: [{ name: "IDPERIODO", value: idPeriodo }]
    });
    // 2. Create blob link to download
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `${TITLE_SISTEMA_ABREV}-REPORTE-OK-${DATE_LIKE_NAME}.xlsx`);
    // 3. Append to html page
    document.body.appendChild(link);
    // 4. Force download
    link.click();
    // 5. Clean up and remove the link
    link.parentNode.removeChild(link);
  };

  return (
    <Fragment>
      <TitleHeader title="Reportes" />
      {(rol === OBJ_ROLES.DIRECTIVO || rol === OBJ_ROLES.ADMINISTRADOR) && (
        <div className="flex flex-col gap-2 md:flex-row flex-wrap">
          <CardReport
            onClick={downloadExcelResumen}
            desc="Descargar resumen inscritos"
            imgSrc={lista_usuario_icon}
            imgAlt="Reporte de inscritos"
          />

          <CardReport
            onClick={downloadInscritosPorCanal}
            desc="Cantidad por canal"
            imgSrc={teamwork_icon}
            imgAlt="Cantidad por canal"
          />

          <CardReport
            onClick={downloadInscritosPorResidencia}
            desc="Reporte por lugar de residencia"
            imgSrc={location_icon}
            imgAlt="Reporte por lugar de residencia"
          />

          <CardReport
            onClick={downloadReportePorSexo}
            desc="Reporte postulantes por sexo"
            imgSrc={restroom_icon}
            imgAlt="Reporte postulantes por sexo"
          />

          <CardReport
            onClick={downloadIngresantes}
            desc="Reporte de ingresantes"
            imgSrc={engineer_icon}
            imgAlt="Reporte de ingresantes"
          />

          <CardReport
            onClick={downloadIngresantesFirmadosObs}
            desc="Reporte de ingresantes firmados observados"
            imgSrc={observation_icon}
            imgAlt="Reporte de ingresantes firmados observados"
          />

          <CardReport
            onClick={downloadReporteFotografiaRecortada}
            desc="Reporte de recorte de fotografía"
            imgSrc={camera_icon}
            imgAlt="Reporte de recorte de fotografía"
          />
        </div>
      )}

      {/* Especialista de sistemas */}
      {rol === OBJ_ROLES.ESPECIALISTA_SISTEMAS && (
        <div className="flex flex-col gap-2 md:flex-row flex-wrap">
          <CardReport
            onClick={downloadExcelResumen}
            desc="Descargar resumen inscritos"
            imgSrc={lista_usuario_icon}
            imgAlt="Reporte de inscritos"
          />

          <CardReport
            onClick={downloadReporteMedioPago}
            desc="Reporte por medios de pago"
            imgSrc={money_icon}
            imgAlt="Reporte de medios de pago inscritos"
          />

          <CardReport
            onClick={downloadInscritosPorCanal}
            desc="Cantidad por canal"
            imgSrc={teamwork_icon}
            imgAlt="Cantidad por canal"
          />

          <CardReport
            onClick={downloadInscritosPorResidencia}
            desc="Reporte por lugar de residencia"
            imgSrc={location_icon}
            imgAlt="Reporte por lugar de residencia"
          />

          <CardReport
            onClick={downloadReportePorSexo}
            desc="Reporte postulantes por sexo"
            imgSrc={restroom_icon}
            imgAlt="Reporte postulantes por sexo"
          />

          <CardReport
            onClick={downloadPostulanteDetallado}
            desc="Reporte detallado de con incompletos"
            imgSrc={note_icon}
            imgAlt="Reporte detallado de con incompletos"
          />

          <CardReport
            onClick={downloadReporteDetalladoOK}
            desc="Reporte detallado postulantes OK"
            imgSrc={check_icon}
            imgAlt="Reporte detallado postulantes OK"
          />
        </div>
      )}

      {/* Segunda especialidad */}
      <div className="flex flex-col gap-2 md:flex-row">
        {rol === OBJ_ROLES.SEGUNDA_ESPECIALIDAD && (
          <CardReport
            onClick={downloadExcelSegundaEspecialidadDetallado}
            desc="Descargar inscritos"
            imgSrc={search_icon}
            imgAlt="Reporte de inscritos detallado"
          />
        )}
      </div>

      {rol === OBJ_ROLES.SUPERADMIN && (
        <div className="flex flex-col gap-2 md:flex-row flex-wrap">
          <CardReport
            onClick={downloadExcelResumen}
            desc="Descargar resumen inscritos"
            imgSrc={lista_usuario_icon}
            imgAlt="Reporte de inscritos"
          />

          <CardReport
            onClick={downloadReporteMedioPago}
            desc="Reporte por medios de pago"
            imgSrc={money_icon}
            imgAlt="Reporte de medios de pago inscritos"
          />

          <CardReport
            onClick={downloadInscritosPorCanal}
            desc="Cantidad por canal"
            imgSrc={teamwork_icon}
            imgAlt="Cantidad por canal"
          />

          <CardReport
            onClick={downloadInscritosPorResidencia}
            desc="Reporte por lugar de residencia"
            imgSrc={location_icon}
            imgAlt="Reporte por lugar de residencia"
          />

          <CardReport
            onClick={downloadExcelSegundaEspecialidadDetallado}
            desc="Reporte segunda especialidad detallado"
            imgSrc={search_icon}
            imgAlt="Reporte de inscritos detallado"
          />

          <CardReport
            onClick={downloadPostulanteDetallado}
            desc="Reporte detallado de postulantes"
            imgSrc={note_icon}
            imgAlt="Reporte detallado de postulantes"
          />

          <CardReport
            onClick={downloadReportePorSexo}
            desc="Reporte postulantes por sexo"
            imgSrc={restroom_icon}
            imgAlt="Reporte postulantes por sexo"
          />

          <CardReport
            onClick={downloadIngresantes}
            desc="Reporte de ingresantes"
            imgSrc={engineer_icon}
            imgAlt="Reporte de ingresantes"
          />

          <CardReport
            onClick={downloadIngresantesFirmadosObs}
            desc="Reporte de ingresantes firmados observados"
            imgSrc={observation_icon}
            imgAlt="Reporte de ingresantes firmados observados"
          />

          <CardReport
            onClick={downloadReporteFotografiaRecortada}
            desc="Reporte de recorte de fotografía"
            imgSrc={camera_icon}
            imgAlt="Reporte de recorte de fotografía"
          />

          <CardReport
            onClick={downloadReporteDetalladoOK}
            desc="Reporte detallado postulantes OK"
            imgSrc={check_icon}
            imgAlt="Reporte detallado postulantes OK"
          />
        </div>
      )}
    </Fragment>
  );
}

export default Reportes;
